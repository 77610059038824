
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ContentRow extends Vue {
    @Prop()
    private readonly backgroundColour!: string;

    @Prop()
    private readonly darken!: boolean;

    @Prop()
    private readonly backgroundImage!: string;

    @Prop()
    private readonly noPadding!: boolean;

    @Prop()
    private readonly borderTop!: string;

    @Prop()
    private readonly borderBottom!: string;
}
