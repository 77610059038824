
import { Testimonial } from 'client-website-ts-library/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import Cycler from './Cycler/Cycler.vue';
import CyclerItem from './Cycler/CyclerItem.vue';

@Component({
  components: {
    Cycler,
    CyclerItem,
  },
})
export default class TestimonialCycler extends Vue {
  @Prop()
  private testimonials!: Testimonial[];

  get bgTestimonials(): Testimonial[] {
    return this.testimonials.slice(0, 48);
  }
}
