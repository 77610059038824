import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/properties-for-sale',
    name: 'Properties For Sale',
    component: () => import('../views/PropertiesForSale.vue'),
  },
  {
    path: '/buyers-club',
    name: 'Buyers Club',
    component: () => import('../views/BuyersClub.vue'),
  },
  {
    path: '/mortgage-calculator',
    name: 'Mortgage Calculator',
    component: () => import('../views/Calculators.vue'),
  },
  {
    path: '/careers',
    name: 'Careers',
    component: () => import('../views/Careers.vue'),
  },
  {
    path: '/open-homes',
    name: 'Inspection Times',
    component: () => import('../views/InspectionTimes.vue'),
  },
  {
    path: '/thinking-of-selling',
    name: 'Thinking of Selling',
    component: () => import('../views/ThinkingOfSelling.vue'),
  },
  {
    path: '/sold',
    name: 'Sold',
    component: () => import('../views/Sold.vue'),
  },
  {
    path: '/request-an-appraisal',
    name: 'Sales Appraisal',
    component: () => import('../views/SalesAppraisal.vue'),
  },
  {
    path: '/our-services',
    name: 'Our Services',
    component: () => import('../views/OurServices.vue'),
  },
  {
    path: '/why-garry-walker-real-estate',
    name: 'Why Garry Walker Real Estate',
    component: () => import('../views/WhyGarryWalkerRealEstate.vue'),
  },
  {
    path: '/testimonials',
    name: 'Testimonials',
    component: () => import('../views/Testimonials.vue'),
  },
  {
    path: '/testimonials-sales',
    name: 'Testimonials Sales',
    component: () => import('../views/TestimonialsSales.vue'),
  },
  {
    path: '/properties-for-rent',
    name: 'Properties For Rent',
    component: () => import('../views/PropertiesForRent.vue'),
  },
  {
    path: '/rental-application',
    name: 'Rental Application',
    component: () => import('../views/RentalApplication.vue'),
  },
  {
    path: '/rental-appraisal',
    name: 'Rental Appraisal',
    component: () => import('../views/RentalAppraisal.vue'),
  },
  {
    path: '/maintenance-request-form',
    name: 'Maintenance Request Form',
    component: () => import('../views/MaintenanceRequestForm.vue'),
  },
  {
    path: '/about-us',
    name: 'About',
    component: () => import('../views/About.vue'),
  },
  {
    path: '/meet-the-team',
    name: 'Meet The Team',
    component: () => import('../views/About.vue'),
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('../views/Blog.vue'),
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: () => import('../views/ContactUs.vue'),
  },
  {
    path: '/profiles/:id/appraisal',
    name: 'Profile Appraisal',
    meta: { hide: true },
    component: () => import('../views/AgentProfileAppraisal.vue'),
  },
  {
    path: '/listings/:id',
    name: 'Listing',
    component: () => import('../views/ListingDetails.vue'),
    meta: {
      hide: true,
    },
  },
  {
    path: '/listings/:id/make-an-offer',
    name: 'Make an Offer',
    component: () => import('../views/ListingMakeAnOffer.vue'),
    meta: {
      hide: true,
    },
  },
  {
    path: '/blogs/:id',
    name: 'Blog',
    component: () => import('../views/BlogDetails.vue'),
    meta: {
      hide: true,
    },
  },
  {
    path: '/profiles/:id',
    name: 'Profile',
    component: () => import('../views/AgentProfile.vue'),
    meta: {
      hideFooterTestimonials: true,
      hide: true,
    },
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    component: () => import('../views/Sitemap.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    component: () => import('../views/Unsubscribe.vue'),
    meta: {
      hide: true,
    },
  },
  {
    path: '/*',
    name: 'Not Found',
    component: () => import('../views/NotFound.vue'),
    meta: {
      hide: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
});

export default router;
