
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Cards extends Vue {
  @Prop({ default: 'var(--card-default-per-row)' })
  private readonly perRow!: number;

  @Prop({ default: 'var(--card-default-spacing)' })
  private readonly spacing!: string;

  @Prop({ default: 'var(--card-md-default-per-row)' })
  private readonly mdPerRow!: number;

  @Prop({ default: 'var(--card-sm-default-per-row)' })
  private readonly smPerRow!: number;

  @Prop({ default: 'var(--card-xs-default-per-row)' })
  private readonly xsPerRow!: number;

  @Prop({ default: 'flex-start' })
  private readonly hAlign!: string;

  @Prop({ default: 'stretch' })
  private readonly vAlign!: string;
}
