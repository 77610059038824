
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import MultiSelect, { MultiSelectOption } from 'client-website-ts-library/components/MultiSelect.vue';
import { ListingFilter, ListingOrderBy } from 'client-website-ts-library/filters';
import { ListingCategory, ListingStatus, PropertyType } from 'client-website-ts-library/types';
import { API, Logger, LogLevel } from 'client-website-ts-library/services';
import Button from './UI/Button.vue';
import Row from './Layout/Row.vue';
import Icon from './UI/Icon.vue';

@Component({
  components: {
    MultiSelect,
  },
})
export default class ListingSearch extends Vue {
  @Prop({ default: 'buy' })
  private readonly methodOfSale!: string;

  @Prop()
  private readonly navigate!: boolean;

  @Prop()
  private readonly type!: string;

  private showAdvancedSearch = false;

  private showSearchFilter = false;

  private searchType: string = this.type;

  private minPrice = '0';

  private maxPrice = '0';

  private minBeds = '0';

  private minBaths = '0';

  private minParking = '0';

  private commercialAreaMin = '0';

  private commercialAreaMax = '0';

  private excludeUnderContract = 'no';

  private houseCheckBox = 'no';

  private apartmentCheckBox = 'no';

  private landCheckBox = 'no';

  private moneyFormatter = new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD', minimumFractionDigits: 0 });

  private mos: string = this.methodOfSale || 'buy';

  private filter: ListingFilter = new ListingFilter({
    Statuses: [
      ListingStatus.Current,
      ListingStatus.UnderContract,
    ],
    PageSize: 9,
  });

  private suburbs: MultiSelectOption[] = [];

  private selectedSuburbs: MultiSelectOption[] = [];

  mounted() {
    try {
      this.minBeds = this.$route.query.minBeds ? (this.$route.query.minBeds as string) : '0';
      this.minBaths = this.$route.query.minBaths ? (this.$route.query.minBaths as string) : '0';
      this.minParking = this.$route.query.minParking ? (this.$route.query.minParking as string) : '0';

      this.minPrice = this.$route.query.minPrice ? (this.$route.query.minPrice as string) : '0';
      this.maxPrice = this.$route.query.maxPrice ? (this.$route.query.maxPrice as string) : '0';
    } catch (ex) {
      Logger.Log(LogLevel.Warning, '[ListingMap]', 'Failed to parse beds, baths, min price and max price from query');
    }

    this.updateSuburbs().then(() => {
      try {
        if (this.$route.query.suburbs) {
          const suburbs = (this.$route.query.suburbs as string).split('|');
          (this.$refs.SuburbMultiSelect as MultiSelect).setValues(suburbs);
          this.filter!.Suburbs = suburbs;
          console.log('ABC');
        }
      } catch (ex) {
        console.error(ex);
        Logger.Log(LogLevel.Warning, '[ListingMap]', 'Failed to parse suburb multi select from query');
      }
    });

    this.updateFilter();
  }

  @Watch('methodOfSale')
  updateMos() {
    this.mos = this.methodOfSale || 'buy';
  }

  private updateSuburbsDebounce: number | undefined = undefined;

  /* updateSuburbs() {
    clearTimeout(this.updateSuburbsDebounce);

    this.updateSuburbsDebounce = window.setTimeout(() => {
      const tmpFilter = new ListingFilter(this.filter);

      tmpFilter.Suburbs = [];

      API.Listings.GetSuburbs(tmpFilter).then((suburbs) => {
        this.suburbs = suburbs.map((suburb) => ({ Value: suburb, Label: suburb }));
      });
    }, 2000);
  } */

  private updateSuburbCallbacks: ((result: (void | PromiseLike<void>)) => void)[] = []

  updateSuburbs(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.filter) return;

      this.updateSuburbCallbacks.push(resolve);

      clearTimeout(this.updateSuburbsDebounce);

      this.updateSuburbsDebounce = window.setTimeout(() => {
        const tmpFilter = new ListingFilter(this.filter);

        tmpFilter.Suburbs = [];

        API.Listings.GetSuburbs(tmpFilter).then((suburbs) => {
          // this.suburbs = suburbs.map((suburb) => ({ Value: `suburb:${suburb}`, Label: suburb.toUpperCase() }));
          this.suburbs = suburbs.map((suburb) => ({ Value: suburb, Label: suburb }));

          const callbacks = [...this.updateSuburbCallbacks];
          this.updateSuburbCallbacks = [];

          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < callbacks.length; i++) {
            callbacks[i]();
          }
        });
      }, 2000);
    });
  }

  private hasUpdated = false

  @Watch('mos')
  clearPrice() {
    this.filter.MinPrice = this.minPrice;
    this.filter.MaxPrice = this.maxPrice;
    this.minPrice = '0';
    this.maxPrice = '0';
  }

  @Watch('mos')
  updateFilter(onPage = false) {
    this.searchType = this.mos;

    switch (this.mos) {
      case 'buy':
        this.filter.Categories = [
          ListingCategory.ResidentialSale,
          ListingCategory.ResidentialLand,
          ListingCategory.Rural,
          ListingCategory.Commercial,
          ListingCategory.CommercialLand,
        ];
        if (this.excludeUnderContract === 'yes') this.filter.Statuses = [ListingStatus.Current];
        if (this.excludeUnderContract === 'no') this.filter.Statuses = [ListingStatus.Current, ListingStatus.UnderContract];
        this.filter.OrderByStatements = [ListingOrderBy.CreateDateDesc];
        break;
      case 'rent':
        this.filter.Categories = [
          ListingCategory.ResidentialRental,
        ];
        this.filter.OrderByStatements = [ListingOrderBy.CreateDateDesc];
        break;
      case 'sold':
        this.filter.Statuses = [
          ListingStatus.Sold,
        ];
        this.filter.OrderByStatements = [ListingOrderBy.SoldDateDesc];
        break;
      default:
        this.filter.Categories = [];
        this.filter.OrderByStatements = [ListingOrderBy.CreateDateDesc];
        break;
    }

    if (this.minPrice) this.filter.MinPrice = this.minPrice;
    if (this.maxPrice) this.filter.MaxPrice = this.maxPrice;

    if (this.minBeds) this.filter.MinBedrooms = this.minBeds;
    if (this.minBaths) this.filter.MinBathrooms = this.minBaths;
    if (this.minParking) this.filter.MinParking = this.minParking;
    /* Resets property types to all and then adds property type filters based on checkboxes in filter popup */
    this.filter.PropertyTypes = [];
    if (this.houseCheckBox === 'yes') this.filter.PropertyTypes.push(PropertyType.House);
    if (this.apartmentCheckBox === 'yes') {
      this.filter.PropertyTypes.push(PropertyType.Unit);
      this.filter.PropertyTypes.push(PropertyType.Apartment);
      this.filter.PropertyTypes.push(PropertyType.Duplex);
      this.filter.PropertyTypes.push(PropertyType.BlockOfUnits);
      this.filter.PropertyTypes.push(PropertyType.Flat);
      this.filter.PropertyTypes.push(PropertyType.SemiDetached);
      this.filter.PropertyTypes.push(PropertyType.ServicedApartment);
      this.filter.PropertyTypes.push(PropertyType.Studio);
      this.filter.PropertyTypes.push(PropertyType.Terrace);
      this.filter.PropertyTypes.push(PropertyType.Townhouse);
      this.filter.PropertyTypes.push(PropertyType.Villa);
      this.filter.PropertyTypes.push(PropertyType.Retirement);
    }
    if (this.landCheckBox === 'yes') {
      this.filter.PropertyTypes.push(PropertyType.Acreage);
      this.filter.PropertyTypes.push(PropertyType.Alpine);
      this.filter.PropertyTypes.push(PropertyType.Cropping);
      this.filter.PropertyTypes.push(PropertyType.Dairy);
      this.filter.PropertyTypes.push(PropertyType.Farmlet);
      this.filter.PropertyTypes.push(PropertyType.Horticulture);
      this.filter.PropertyTypes.push(PropertyType.Livestock);
      this.filter.PropertyTypes.push(PropertyType.MixedFarming);
      this.filter.PropertyTypes.push(PropertyType.Viticulture);
      this.filter.PropertyTypes.push(PropertyType.Other);
    }

    this.updateSuburbs();

    if (onPage) {
      this.filter.Suburbs = this.selectedSuburbs.map((s) => s.Value);
    } else {
      this.filter.Suburbs = this.$route.query.suburbs ? this.$route.query.suburbs as string[] : [];
    }

    this.filter.Page = 1;

    if (this.filter.Statuses.length === 1 && this.filter.Statuses[0] === ListingStatus.Sold) {
      this.filter.OrderByStatements = [ListingOrderBy.SoldDateDesc];
    } else {
      this.filter.OrderByStatements = [ListingOrderBy.CreateDateDesc];
    }

    const opts = this.selectedSuburbs.map((s) => {
      const split = s.Value.split(':');

      return {
        Type: split[0],
        Value: split[1],
      };
    });

    const customOpt = opts.find((s) => s.Type === 'search');

    if (customOpt) {
      this.filter.SearchTerm = customOpt.Value;
    } else {
      this.filter.SearchTerm = '';
    }

    this.hasUpdated = true;

    this.emitFilter();
  }

  suburbsUpdated(selectedSuburbs: MultiSelectOption[]) {
    this.selectedSuburbs = selectedSuburbs;

    this.updateFilter();
  }

  private emitDebounce: number | undefined = undefined;

  emitFilter() {
    this.$emit('filter_updated', this.filter);
  }

  toggleAdvancedSearch() {
    console.log(this.showAdvancedSearch);
    this.showAdvancedSearch = !this.showAdvancedSearch;
  }

  toggleFilters() {
    this.showSearchFilter = !this.showSearchFilter;
  }

  updateMethodOfSale(value) {
    this.mos = value;
  }

  hideFilters() {
    this.showSearchFilter = false;
  }

  doSearch() {
    if (this.navigate) {
      this.updateFilter(true);
      switch (this.mos) {
        case 'buy':
          this.$router.push({
            path: '/properties-for-sale',
            query: {
              suburbs: this.filter.Suburbs.join('|'),
              minBeds: this.minBeds.toString(),
              minBaths: this.minBaths.toString(),
              minParking: this.minParking.toString(),
              minPrice: this.minPrice.toString(),
              maxPrice: this.maxPrice.toString(),
            },
          });
          break;
        case 'rent':
          this.$router.push({
            path: '/properties-for-rent',
            query: {
              suburbs: this.filter.Suburbs.join('|'),
              minBeds: this.minBeds.toString(),
              minBaths: this.minBaths.toString(),
              minParking: this.minParking.toString(),
              minPrice: this.minPrice.toString(),
              maxPrice: this.maxPrice.toString(),
            },
          });
          break;
        case 'sold':
          this.$router.push({
            path: '/sold',
            query: {
              suburbs: this.filter.Suburbs.join('|'),
              minBeds: this.minBeds.toString(),
              minBaths: this.minBaths.toString(),
              minParking: this.minParking.toString(),
              minPrice: this.minPrice.toString(),
              maxPrice: this.maxPrice.toString(),
            },
          });
          break;
        default:

          break;
      }
    } else {
      // REPLACE URL
      this.updateFilter(true);
      switch (this.mos) {
        case 'buy':
          this.$router.replace({
            path: '/properties-for-sale',
            query: {
              suburbs: this.filter.Suburbs.join('|'),
              minBeds: this.minBeds.toString(),
              minBaths: this.minBaths.toString(),
              minParking: this.minParking.toString(),
              minPrice: this.minPrice.toString(),
              maxPrice: this.maxPrice.toString(),
            },
          });
          break;
        case 'rent':
          this.$router.replace({
            path: '/properties-for-rent',
            query: {
              suburbs: this.filter.Suburbs.join('|'),
              minBeds: this.minBeds.toString(),
              minBaths: this.minBaths.toString(),
              minParking: this.minParking.toString(),
              minPrice: this.minPrice.toString(),
              maxPrice: this.maxPrice.toString(),
            },
          });
          break;
        case 'sold':
          this.$router.replace({
            path: '/sold',
            query: {
              suburbs: this.filter.Suburbs.join('|'),
              minBeds: this.minBeds.toString(),
              minBaths: this.minBaths.toString(),
              minParking: this.minParking.toString(),
              minPrice: this.minPrice.toString(),
              maxPrice: this.maxPrice.toString(),
            },
          });
          break;
        default:

          break;
      }
    }
  }
}
