
import { Listing } from 'client-website-ts-library/types';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SocialSharer extends Vue {
  @Prop({ required: true })
  private url!: string;

  @Prop({ required: true })
  private listingId!: string;

  @Prop({ required: true })
  private listing!: Listing;

  print(): void {
    window.print();
  }

  shareFB(): void {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.origin + this.url)}`,
      '_blank',
      'toolbar=0,status=0,resizable=1,width=626,height=436',
    );
  }
}
